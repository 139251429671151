/* eslint-disable */ 
import eventListStore from '@/store/event/event-list-store';
import eventFormStore from '@/store/event/event-form-store';
import eventViewStore from '@/store/event/event-view-store';
import eventDestroyStore from '@/store/event/event-destroy-store';

export default {
  namespaced: true,

  modules: {
    list: eventListStore,
    form: eventFormStore,
    view: eventViewStore,
    destroy: eventDestroyStore
  },
};
